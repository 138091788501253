import Worker from 'worker-loader!./web-worker';
import createApp from './main';

import './style/company/index.css';

import CompanyHeader from './components/CompanyHeader';
import CompanyFooter from './components/CompanyFooter';
import { EnvironmentOptions } from './EnvironmentOptions';

export default function (environmentOptions: EnvironmentOptions, workerFactory?: () => Worker) {
  return createApp(
    environmentOptions,
    {
      header: CompanyHeader,
      footer: CompanyFooter,
    },
    workerFactory);
}
